/* eslint-disable eqeqeq */
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import { Steps } from 'primereact/steps';
import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import { IPropsParamStepDialog } from '../generics/formInterfaces';

import MimeType from 'mime';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { ToggleButton, ToggleButtonChangeEvent } from 'primereact/togglebutton';
import ls from '../../../projects/localStorage';
import { isSuccess } from '../../../utils/httpStatus';
import QuickMessageBranchGymService from '../../service/admin/QuickMessageBranchGymService';
import QuickMessageService from '../../service/admin/QuickMessageService';

const quickMessageService = new QuickMessageService();
const quickMessageBranchGymService = new QuickMessageBranchGymService();

export function StepDialog<T>(props: IPropsParamStepDialog<T>) {
    const [networkGymId] = useState<string>(props.args.networGymkId);
    const [branchGymData, setBranchGymData] = useState<any[] | null>();
    const [activeIndex, setActiveIndex] = useState(0);
    const [quickMessageId, setQuickMessageId] = useState<string | null>(null);
    const [typeQuickMessage, setTypeQuickMessage] = useState(0);
    const [textQuickMessage, setTextQuickMessage] = useState('');
    const [mediaQuickMessage, setMediaQuickMessage] = useState<string | undefined>(undefined);
    const [mimeTypeQuickMessage, setMimeTypeQuickMessage] = useState('');
    const [listSelectBranchGymData, setListSelectBranchGymData] = useState<any[]>();
    const [hasOneBranchGymSelected, setHasOneBranchGymSelected] = useState(false);
    const [tagQuickMessage, setTagQuickMessage] = useState('');
    const [messageErrorTag, setMessageErrorTag] = useState('');
    const [titleQuickMessage, setTitleQuickMessage] = useState('');
    const [quickMessageBranchGymData, setQuickMessageBranchGymData] = useState<any[]>();

    const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => refreshData(props.editStepData), [props.editStepData]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { refreshListSelectBranchGymData(); }, [branchGymData, props.editStepData]);

    const refreshData = (editStepData: any) => {
        props.args.setLoading(true);
        if (editStepData && editStepData._id) {
            setQuickMessageId(editStepData._id)
            setActiveIndex(editStepData._id ? 1 : 0);
            setTypeQuickMessage(editStepData.isObject ? 2 : 1);
            setTextQuickMessage(editStepData.text || '');
            setMediaQuickMessage(editStepData.mediaBase64 || '');
            setMimeTypeQuickMessage(editStepData.mimetype || '');
            // setListSelectBranchGymData(editStepData.text || '');
            // setHasOneBranchGymSelected(editStepData.text || '');
            setTagQuickMessage(editStepData.tag || '');
            setTitleQuickMessage(editStepData.title || '');
        } else {
            setQuickMessageId(null)
            setActiveIndex(0);
            setTypeQuickMessage(0);
            setTextQuickMessage('');
            setMediaQuickMessage('');
            setMimeTypeQuickMessage('');
            // setListSelectBranchGymData('');
            // setHasOneBranchGymSelected('');
            setTagQuickMessage('');
            setTitleQuickMessage('');
        }
        refreshListBranchGyms();
        props.args.setLoading(false);
    }


    const refreshListSelectBranchGymData = async () => {
        props.args.setLoading(true);
        // console.log('PASSOU 2:', branchGymData?.length);
        let list: any[] = [];
        if (branchGymData && branchGymData.length >= 2)
            list.push({ id: 'all', name: 'Todos', selected: false });

        branchGymData?.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)?.forEach((b) => {
            list.push({ id: b._id, name: b.name, selected: branchGymData && branchGymData.length === 1 ? true : false });
        });
        // console.log('PASSOU 3:', list?.length, props.editStepData?._id);

        if (props.editStepData && props.editStepData._id) {
            list = await refreshQuickMessageBranchGymList(list, props.editStepData._id);
        } else {
            setQuickMessageBranchGymData([]);
        }

        // console.log('PASSOU 4:', list?.length);
        if (list) {
            let value = list.reduce((pv: boolean, cur: any) => pv || cur.selected, false);
            setHasOneBranchGymSelected(value);
        } else {
            setHasOneBranchGymSelected(false);
        }

        // console.log('PASSOU 5:', list?.length);
        setListSelectBranchGymData([...list]);
        props.args.setLoading(false);
    }


    const refreshQuickMessageBranchGymList = (listSelectBranchGymData: any[], quickMessageId: string): Promise<any[]> => {
        return new Promise((resolve, reject) => {
            props.args.setLoading(true);
            let list = listSelectBranchGymData || [];
            list.forEach((l: any) => l.selected = false);

            quickMessageBranchGymService.findQuickMessageBranchGymByQuickMessageId(quickMessageId).then(result => {
                setQuickMessageBranchGymData(result.data);
                for (let i = 0; i < result.data.length; i++) {
                    const quickMessageBranchGym = result.data[i];
                    if (quickMessageBranchGym.quickMessage._id === quickMessageId)
                        list = refreshQuickMessageBranchGymListOption(list, quickMessageBranchGym?.branchGym?._id, true);
                    else
                        list = refreshQuickMessageBranchGymListOption(list, quickMessageBranchGym?.branchGym?._id, false);
                }
                resolve([...list]);
                props.args.setLoading(false);
            }).catch(error => {
                resolve([...list]);
                props.args.setLoading(false);
            });
        })
    }


    const refreshQuickMessageBranchGymListOption = (listSelectBranchGymData: any[], branchGymId: string, select: boolean) => {
        props.args.setLoading(true);
        if (listSelectBranchGymData) {
            const list = listSelectBranchGymData || [];
            list.forEach((l: any) => {
                if (l.id === branchGymId || branchGymId === 'all') l.selected = select;
            });
            let selectAll = true;
            list.forEach(l => selectAll = selectAll && (l.selected || l.id === 'all'));
            // let value = list.reduce((pv: boolean, cur: any) => pv || cur.selected, false);
            props.args.setLoading(false);
            return [...list.map(l => l.id === 'all' ? { id: l.id, name: l.name, selected: selectAll } : l)];
        } else {
            props.args.setLoading(false);
            return [];
        }
    }

    const refreshListBranchGyms = async () => {
        let isMounted = true;
        props.args.setLoading(true);
        const branchGymsByStorageToken = await ls.branchGym.getBranchGymsByStorageToken();

        if (isMounted) {
            const branchGymFiltereds = ls.branchGym.getAllBranchGyms({
                networkGymId,
                branchGymIds: branchGymsByStorageToken?.map(b => b._id),
                nonpayer: false,
                unsubscribe: false
            });
            if (branchGymFiltereds) setBranchGymData([...branchGymFiltereds]);
            else setBranchGymData([])
        }
        props.args.setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return () => { isMounted = false };
    };

    const items = [
        {
            label: 'Tipo'
        },
        {
            label: 'Mensagem'
        },
        {
            label: 'Unidades'
        },
        {
            label: 'Ação Rápida'
        },
        {
            label: 'Conclusão'
        }
    ];


    const saveQuickMessage = () => {
        if (saveButtonDisabled) return;
        setSaveButtonDisabled(true);
        props.args.setLoading(true);

        const quickMessage: any = {
            networkGym: networkGymId,
            holder: ls.customer.getCustomerIdStorageToken(),
            title: titleQuickMessage,
            isObject: typeQuickMessage === 2,
            text: typeQuickMessage === 1 ? textQuickMessage : null,
            mediaBase64: typeQuickMessage === 2 ? mediaQuickMessage : null,
            mimetype: typeQuickMessage === 2 ? mimeTypeQuickMessage : null,
            tag: tagQuickMessage,
            blocked: false
        }

        if (quickMessageId) quickMessage._id = quickMessageId;

        quickMessageService.saveQuickMessage(quickMessage)
            .then((returned: any) => {
                if (isSuccess(returned.status)) {
                    if (quickMessageBranchGymData) {
                        for (let i = 0; i < quickMessageBranchGymData.length; i++) {
                            const quickMessageBranchGym = quickMessageBranchGymData[i];
                            if (quickMessageBranchGym.quickMessage._id === quickMessage._id) {
                                quickMessageBranchGymService.deleteByQuickMessageBranchGymId(quickMessageBranchGym._id);
                            }
                        }
                    }
                    listSelectBranchGymData?.filter(l => l.selected && l.id != 'all').forEach(l => {
                        quickMessageBranchGymService.saveQuickMessageBranchGym({
                            quickMessage: returned.data._id,
                            branchGym: l.id
                        }).then((resultBrachGym: any) => {
                            if (isSuccess(resultBrachGym.status)) {
                                // props.args.setMessage({ severity: 'success', summary: `Mensagem rápida registrada com sucesso!`, detail: '', life: 10000 });
                            } else {
                                props.args.setMessage({ severity: 'fail', summary: `Falha ao cadastrar a mensagem rápida!`, detail: '', life: 10000 });
                            }
                        })
                            .catch((error: any) => props.args.setMessage({ severity: 'fail', summary: `Falha ao cadastrar a mensagem rápida!`, detail: '', life: 10000 }));
                    });
                    props.args.setMessage({ severity: 'success', summary: `Mensagem rápida registrada com sucesso!`, detail: '', life: 10000 });
                    closeStepDialog();
                    props.args.setLoading(false);
                    props.refreshStepData();
                    setSaveButtonDisabled(false);
                } else {
                    props.args.setMessage({ severity: 'fail', summary: `Falha ao cadastrar a mensagem rápida!`, detail: '', life: 10000 });
                    props.args.setLoading(false);
                    props.refreshStepData();
                    setSaveButtonDisabled(false);
                }
            })
            .catch((error: any) => { props.args.setLoading(false); props.args.setMessage({ severity: 'fail', summary: `Falha ao cadastrar a mensagem rápida!`, detail: '', life: 10000 }); });
    }

    const onChangeListOption = (branchGymId: string, select: boolean) => {
        if (listSelectBranchGymData) {
            const list = listSelectBranchGymData || [];
            list.forEach((l: any) => {
                if (l.id === branchGymId || branchGymId === 'all') l.selected = select;
            });
            let selectAll = true;
            list.forEach(l => selectAll = selectAll && (l.selected || l.id === 'all'));

            let value = list.reduce((pv: boolean, cur: any) => pv || cur.selected, false);
            setHasOneBranchGymSelected(value);

            setListSelectBranchGymData([...list.map(l => l.id === 'all' ? { id: l.id, name: l.name, selected: selectAll } : l)]);
        }
    }

    const listDataBranchGyms = () => {
        props.args.setLoading(true);
        const selectAreaListOptions = listSelectBranchGymData?.map((o: any) => {
            return <div key={o.id} className='col-4'>
                <ToggleButton id={o.id} checked={o.selected} style={{ minWidth: '20rem' }}
                    onLabel={o.name} offLabel={o.name} onIcon="pi pi-check-square" offIcon="pi pi-stop"
                    tooltip={o.name} onChange={(e: ToggleButtonChangeEvent) => {
                        // o[selected] = o.value;
                        onChangeListOption(o.id, e.value);
                    }} />
            </div>
        });

        props.args.setLoading(false);
        return (<span>
            {/* <label htmlFor={selectAreaList.fieldName} className={classNames({ 'p-error': isFormFieldValid(selectAreaList.fieldName) })}>{selectAreaList.description}</label> */}
            <div className='card flex flex-wrap justify-content-left' style={{ backgroundColor: 'rgba(17, 140, 172, 0.135)' }}>
                {selectAreaListOptions}
            </div>
        </span>);
    }

    const listBranchGymsSelecteds = () => {
        return (<span>
            {/* <label htmlFor={selectAreaList.fieldName} className={classNames({ 'p-error': isFormFieldValid(selectAreaList.fieldName) })}>{selectAreaList.description}</label> */}
            <div className='card flex flex-wrap justify-content-left' style={{ backgroundColor: 'rgba(17, 140, 172, 0.135)' }}>
                {listSelectBranchGymData?.filter(o => o.selected && o.id !== 'all').map(o => o.name).join()}
            </div>
        </span>);
    }

    const prettyMessage = (message: string) => {
        return message.replace(/```([a-z][\S\s]+?)```/gmi, '<span style="font-size: 0.7rem; font-style: italic;">$1</span>')
            .replace(/\*([a-z][\S\s]+?)\*/gmi, '<span style="font-weight: bold;">$1</span>')
            .replace(/_([a-z][\S\s]+?)_/gmi, '<span style="font-style: italic;">$1</span>')
            .replace(/\n/g, "<br />");
    }

    const customBase64Uploader = async (event: { files: any[]; }) => {
        setMimeTypeQuickMessage('');
        setMediaQuickMessage(undefined);

        props.args.setLoading(true);
        // convert file to base64 encoded
        const file = event.files[0];
        if (file.size >= 10240000) {
            props.args.setMessage({ severity: 'warn', summary: 'O arquivo a ser enviado não pode ser superior a 10MB de tamanho.!', detail: '', life: 5000 });
            props.args.setLoading(false);
        } else {
            const reader = new FileReader();
            // let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url

            reader.readAsDataURL(file);

            reader.onloadend = async () => {
                const mimetype = MimeType.getType(file.name);

                if (mimetype && typeof reader.result === 'string') {
                    setMimeTypeQuickMessage(mimetype);
                    setMediaQuickMessage(reader.result);
                    // await sendMediaMessageToContact(reader.result, mimetype, EnumTypeShowMessage.interactionMessage, `messagesSender.media.message.${customerId}`);
                } else {
                    props.args.setMessage({ severity: 'warn', summary: 'Tipo de arquivo não reconhecido ou inválido!', detail: '', life: 5000 });
                }
                props.args.setLoading(false);
            };

            reader.onerror = async () => {
                props.args.setMessage({ severity: 'danger', summary: 'Erro ao carregar o arquivo!', detail: '', life: 5000 });
                props.args.setLoading(false);
            }
        }
        // setMediasMessage(false);
    };

    const processTagInfomated = async () => {
        props.args.setLoading(true);
        setMessageErrorTag('');
        const returnResult = await quickMessageService.allNetworkGymAndTagQuickMessages(networkGymId, tagQuickMessage);

        if (returnResult && returnResult.data && returnResult.data.length > 0 && returnResult.data[0]._id !== quickMessageId) {
            setMessageErrorTag(`Ação rápida já está em uso, informe outra opção!`);
        } else {
            // props.args.setMessage({ severity: 'success', summary: `Mensagem rápida registrada com sucesso!`, detail: '', life: 10000 });
            setActiveIndex(activeIndex + 1);
        }

        props.args.setLoading(false);
    }

    const closeStepDialog = () => {
        props.args.setLoading(true);
        setActiveIndex(0);
        props.refreshStepData();
        props.setEditStepData(null);
        refreshData(null);
        props.setStepDialogData(false);
        props.args.setLoading(false);
    }

    return (
        <Dialog visible={props.showStepDialog} style={{ width: '80%', padding: '2px' }} blockScroll={false} modal={true} closable={false}
            onHide={() => closeStepDialog()}>
            <div className='block bg-primary p-2 mt-4'>
                <i className={classNames('pi', 'pi-fw', { 'pi-table': !props.icon }, props.icon, 'layout-menuitem-icon', 'mr-2')} style={{ fontSize: 24, color: '#FFF' }}></i>
                <span className='title-dialog-value' style={{ fontSize: 24 }}>{
                    props.title ? props.title : ''
                    // props.editFormData?.values?._id ? `Altere os dados d${new RegExp(/o$/, 'ig').test(props?.objectData) ? 'o' : 'a'} ${props.objectData}` : `Criar nov${new RegExp(/o$/, 'ig').test(props?.objectData) ? 'o' : 'a'} ${props.objectData}`
                }</span>
            </div>
            <div className='block bg-primary p-2'>
                <Steps className='block bg-white' model={items} activeIndex={activeIndex} />
            </div>
            <div className='block bg-white font-bold m-2'>
                {activeIndex === 0 ?
                    <span style={{ fontSize: '1.2rem', marginTop: 20 }}>Escolha o tipo de mensagem rápida:</span>
                    :
                    activeIndex === 1 && typeQuickMessage === 1 ?
                        <span style={{ fontSize: '1.2rem', marginTop: 20 }}>Escreva o texto da mensagem rápida:</span>
                        :
                        activeIndex === 1 && typeQuickMessage === 2 ?
                            <span style={{ fontSize: '1.2rem', marginTop: 20 }}>Selecione uma mídia para mensagem rápida (limite 10MB):</span>
                            :
                            activeIndex === 2 ?
                                <span style={{ fontSize: '1.2rem' }}>Selecione quais unidades/departamentos podem utilizar essa mensagem rápida:</span>
                                :
                                activeIndex === 3 ?
                                    <span style={{ fontSize: '1.2rem', marginTop: 20 }}>Defina um acesso rápido para esta mensagem rápida:</span>
                                    :
                                    activeIndex === 4 ?
                                        <span style={{ fontSize: '1.2rem', marginTop: 20 }}>Informe o título e grave sua a mensagem rápida:</span>
                                        :
                                        null
                }
            </div>
            <div className='block bg-white p-2' style={{ backgroundColor: '#000' }}>
                <div className='p-5' hidden={activeIndex !== 0}>
                    <div className="flex flex-wrap justify-content-around" style={{ width: '100%' }}>
                        <div className='flex align-items-center justify-content-center bg-primary font-bold m-2 shadow-3 p-ripple' onClick={() => { setTypeQuickMessage(1); setActiveIndex(activeIndex + 1); }} style={{ minWidth: 200, minHeight: 200, borderRadius: '2em', cursor: 'pointer' }}>
                            <i className="pi pi-align-left mr-5" style={{ fontSize: '2.0rem' }}></i>
                            <span style={{ fontSize: '2.0rem' }}>Texto</span>
                        </div>
                        <div className='flex align-items-center justify-content-center bg-primary font-bold m-2 shadow-3 p-ripple' onClick={() => { setTypeQuickMessage(2); setActiveIndex(activeIndex + 1); }} style={{ minWidth: 200, minHeight: 200, borderRadius: '2em', cursor: 'pointer' }}>
                            <i className="pi pi-images mr-5" style={{ fontSize: '2.0rem' }}></i>
                            <span style={{ fontSize: '2.0rem' }}>Mídia</span>
                        </div>
                    </div>
                </div>
                <div className='p-5 chatBx' hidden={activeIndex !== 1 || typeQuickMessage !== 1}>
                    <div className='grid'>
                        <div className="col-8 flex flex-wrap justify-content-around">
                            <InputTextarea className='textQuickMessage' autoResize={false} value={textQuickMessage} onChange={(e) => setTextQuickMessage(e.target.value)}
                                style={{ fontSize: '1.2em', minWidth: '100%', minHeight: 200 }} placeholder={`Olá! Seja *bem vindo* a nossa academia.\n\nEstou aqui para _esclarecer suas dúvidas_. 🤗`}
                                aria-describedby="textQuickMessage-help" />
                            <small id="textQuickMessage-help">
                                Informe ao menos uma mensagem de 5 caracteres.
                            </small>
                        </div>
                        {textQuickMessage.length > 0 ?
                            <div className="col-4 flex flex-wrap justify-content-center">
                                <div className={classNames('msg', 'msg-me', 'justify-content-center')}>
                                    <div className='flex-initial flex align-items-left justify-content-left'>
                                        <span className='flex-initial' dangerouslySetInnerHTML={{ __html: prettyMessage(textQuickMessage) }}></span>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </div>

                </div>
                <div className='p-5' hidden={activeIndex !== 1 || typeQuickMessage !== 2}>
                    <div className='grid-nogutter'>
                        {!mediaQuickMessage || mediaQuickMessage.length === 0 ?
                            <div className="col-12 flex flex-wrap justify-content-around">
                                <FileUpload mode="basic" accept="image/*" maxFileSize={102400000} chooseOptions={{ icon: 'pi pi-image', style: { minWidth: 200, marginRight: 10, marginTop: '5px' } }} multiple={false} auto chooseLabel="Imagens"
                                    customUpload uploadHandler={(event: { files: any[]; }) => customBase64Uploader(event)} />
                                <FileUpload mode="basic" accept="audio/*" maxFileSize={102400000} chooseOptions={{ icon: 'pi pi-volume-up', style: { minWidth: 200, marginRight: 10, marginTop: '5px' } }} multiple={false} auto chooseLabel="Arquivos de Áudio"
                                    customUpload uploadHandler={(event: { files: any[]; }) => customBase64Uploader(event)} style={{ minWidth: 200, marginRight: 10, marginTop: '5px' }} />
                                {/* <FileUpload mode="basic" accept="video/ogg,video/webm,video/mp4" maxFileSize={102400000} chooseOptions={{ icon: 'pi pi-video', style: { minWidth: 200, marginRight: 10, marginTop: '5px' } }} multiple={false} auto chooseLabel="Vídeos"
                                    customUpload uploadHandler={(event: { files: any[]; }) => customBase64Uploader(event)} style={{ minWidth: 200, marginRight: 10, marginTop: '5px' }} /> */}
                                <FileUpload mode="basic" accept="application/pdf" maxFileSize={102400000} chooseOptions={{ icon: 'pi pi-file-pdf', style: { minWidth: 200, marginRight: 10, marginTop: '5px' } }} multiple={false} auto chooseLabel="PDF's"
                                    customUpload uploadHandler={(event: { files: any[]; }) => customBase64Uploader(event)} style={{ minWidth: 200, marginRight: 10, marginTop: '5px' }} />
                            </div>
                            : null}

                        {mediaQuickMessage && mediaQuickMessage.length > 0 ?
                            previewMedia(mimeTypeQuickMessage, mediaQuickMessage)
                            : null
                        }
                        {mediaQuickMessage && mediaQuickMessage.length > 0 ?
                            <div className="col-12 flex flex-wrap justify-content-center">
                                <Button label='Rejeitar' type='button' onClick={() => { setMimeTypeQuickMessage(''); setMediaQuickMessage(undefined); }} style={{ minWidth: 150 }}
                                    className='p-button-rounded p-button p-button-danger shadow-3' icon='pi pi-undo' />
                            </div>
                            : null
                        }
                    </div>

                </div>
                <div className='p-5' hidden={activeIndex !== 2}>
                    {listDataBranchGyms()}
                </div>
                <div className='p-5' hidden={activeIndex !== 3}>
                    <div className='flex flex-wrap justify-content-around' hidden={activeIndex !== 3}>
                        <div className="grid-nogutter align-items-stretch justify-content-center mt-3">
                            <div className="col-12 align-self-start justify-content-center">
                                <InputText value={tagQuickMessage} maxLength={12} onChange={(e) => setTagQuickMessage((e.target.value).toUpperCase())}
                                    placeholder={`Informe uma ação rápida.`} keyfilter={/^[a-zA-Z0-9_]*$/} aria-describedby="textQuickMessage-help"
                                    style={{ fontSize: '2em', minWidth: '30rem' }} />
                            </div>
                            <div className="col-12 align-items-center justify-content-center">
                                <small id="textQuickMessage-help">
                                    Informe ao menos uma ação rápida de 5 caracteres.
                                </small>
                            </div>
                        </div>
                        {tagQuickMessage.length > 1 ?
                            <div className="flex align-items-center justify-content-center bg-primary font-bold m-2 p-5 border-round" style={{ minWidth: 400 }}>
                                <span style={{ fontSize: '1em', marginRight: 20 }}>Ação rápida: </span>
                                <span style={{ fontSize: '2em' }}>{`#${tagQuickMessage}#`}</span>
                            </div>
                            : null
                        }
                    </div>

                    {messageErrorTag && messageErrorTag.length > 0 ?
                        <div className="col-12 align-items-center justify-content-center" style={{ color: 'red' }}>
                            <small>{messageErrorTag}</small>
                        </div>

                        : null
                    }
                </div>
                <div className='p-5' hidden={activeIndex !== 4}>
                    <div className="grid">
                        <div className="col-8 grid">
                            <div className="col-2">
                                <span style={{ width: 50, fontWeight: 'bold' }}>Título:</span>
                            </div>
                            <div className="col-10">
                                <InputText id='titleQuickMessage' value={titleQuickMessage} maxLength={20} onChange={(e) => setTitleQuickMessage(e.target.value)}
                                    placeholder={`Informe um título para esta mensagem rápida.`} style={{ minWidth: 200 }} />
                            </div>
                            <div className="col-2">
                                <span style={{ width: 50, fontWeight: 'bold' }}>Unidades:</span>
                            </div>
                            <div className="col-10">
                                {listBranchGymsSelecteds()}
                            </div>
                            <div className="col-2">
                                <span style={{ width: 50, fontWeight: 'bold' }}>Ação rápida:</span>
                            </div>
                            <div className="col-10">
                                <div className="flex align-items-center justify-content-center bg-primary font-bold m-0 p-5 border-round" style={{ minWidth: 200 }}>
                                    <span style={{ fontSize: '1.4em' }}>{`#${tagQuickMessage}#`}</span>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            {textQuickMessage.length > 0 ?
                                <div className="chatBx">
                                    {textQuickMessage.length > 0 ?
                                        <div className={classNames('msg', 'msg-me')}>
                                            <div>
                                                <span className='flex-initial' dangerouslySetInnerHTML={{ __html: prettyMessage(textQuickMessage) }}></span>
                                            </div>
                                        </div>
                                        : null
                                    }
                                </div>
                                : null}
                            {mediaQuickMessage && mediaQuickMessage.length > 0 ?
                                previewMedia(mimeTypeQuickMessage, mediaQuickMessage)
                                : null
                            }
                        </div>
                        <div className="field col-12 grid">
                        </div>
                    </div>
                </div>

            </div>
            <div className='flex justify-content-end flex-wrap bg-primary p-3 text-left'>
                {activeIndex !== 0 ?
                    <Button label='Voltar' type='button' onClick={() => { if (activeIndex === 1) setTypeQuickMessage(0); setMediaQuickMessage(''); setTextQuickMessage(''); setMimeTypeQuickMessage(''); setActiveIndex(activeIndex - 1); }} style={{ minWidth: 150 }}
                        className='p-button-rounded p-button p-button-warning shadow-3' icon='pi pi-undo' />
                    : null
                }
                {activeIndex === 1 ?
                    <Button label='Continuar' type='button' disabled={textQuickMessage.length <= 4 && (!mediaQuickMessage || mediaQuickMessage?.length <= 0)} onClick={() => { setActiveIndex(activeIndex + 1); }} style={{ minWidth: 150 }}
                        className='p-button-rounded p-button p-button-primary shadow-3' icon='pi pi-play' />
                    : null
                }
                {activeIndex === 2 ?
                    <Button label='Continuar' type='button' disabled={!hasOneBranchGymSelected} onClick={() => { setActiveIndex(activeIndex + 1); }} style={{ minWidth: 150 }}
                        className='p-button-rounded p-button p-button-primary shadow-3' icon='pi pi-play' />
                    : null
                }
                {activeIndex === 3 ?
                    <Button label='Continuar' type='button' disabled={tagQuickMessage.length <= 4} onClick={() => { processTagInfomated() }} style={{ minWidth: 150 }}
                        className='p-button-rounded p-button p-button-primary shadow-3' icon='pi pi-play' />
                    : null
                }
                {activeIndex === 4 ?
                    <Button label='Gravar' type='button' disabled={titleQuickMessage.length <= 4 || saveButtonDisabled} onClick={() => saveQuickMessage()} style={{ minWidth: 150 }}
                        className='p-button-rounded p-button p-button-primary shadow-3' icon='pi pi-save' />
                    : null
                }
                <Button label='Cancelar' type='button' onClick={() => { closeStepDialog(); }} style={{ minWidth: 150 }}
                    className='p-button-rounded p-button p-button-warning shadow-3' icon='pi pi-times' />
            </div>
        </Dialog>
    );
}

function previewMedia(mimeTypeQuickMessage: string, mediaQuickMessage: string): React.ReactNode {
    return <div className="col-12 flex flex-wrap justify-content-center">
        <div className={classNames('msg', 'msg-me', 'justify-content-center')}>
            {mimeTypeQuickMessage.includes('image') ?
                <div className='flex-initial flex align-items-center justify-content-center'>
                    <Image className='flex-initial flex flex-wrap' downloadable={true} src={mediaQuickMessage} alt="Imagem" height="100" preview />
                </div>
                :
                mimeTypeQuickMessage.includes('video') ?
                    <div className='flex-initial flex align-items-center justify-content-center'>
                        <video className='flex-initial flex flex-wrap' width="400" height="200" controls>
                            <source src={mediaQuickMessage} type={mimeTypeQuickMessage} />
                        </video>
                    </div>
                    :
                    mimeTypeQuickMessage.includes('audio') ?
                        <div className='flex-initial flex align-items-center justify-content-center'>
                            <audio className='flex-initial flex flex-wrap' controls>
                                <source src={mediaQuickMessage} type={mimeTypeQuickMessage} />
                            </audio>
                        </div>
                        :
                        mimeTypeQuickMessage.includes('pdf') ?

                            <div className='flex-initial flex align-items-center justify-content-center'>
                                <i className="pi pi-file-pdf" style={{ fontSize: '4rem' }}></i>
                            </div> :
                            mimeTypeQuickMessage.includes('ms-excel') ?
                                <div className='flex-initial flex align-items-center justify-content-center'>
                                    <i className="pi pi-file-excel" style={{ fontSize: '4rem' }}></i>
                                </div>
                                :

                                mimeTypeQuickMessage.includes('ms-word') ?
                                    <div className='flex-initial flex align-items-center justify-content-center'>
                                        <i className="pi pi-file-word" style={{ fontSize: '4rem' }}></i>
                                    </div>
                                    :
                                    mimeTypeQuickMessage.includes('pdf') ?
                                        <div className='flex-initial flex align-items-center justify-content-center'>
                                            <i className="pi pi-file-pdf" style={{ fontSize: '4rem' }}></i>
                                        </div>
                                        : null}
        </div>
    </div>;
}

