import moment from 'moment';
import { limitCharacters, replaceValue } from '../../../utils/functions/string';
import { StatusSchedule } from '../data/Schedule';

export const Schedule = (props: any) => {
    const schedule = props.info;
    const width = props.width || '260px';

    const scheduleType = moment(schedule.date).diff(moment(), 'minute') < 0 ? schedule.typeSchedule : StatusSchedule.SCHEDULED;

    // const [customerId, setCustomerId] = useState<string | null>(ls.customer.getCustomerIdStorageToken());

    return (
        <div onClick={props.onClick} className={`p-shadow-7 juddon-schedule-type-${scheduleType} juddon-schedule-status-${schedule.statusSchedule}`} style={{ ...props.style, position: 'relative', fontSize: '11px', height: '7rem', width: width, border: '1px solid #707070', borderRadius: 4 }}>
            {
                scheduleType === StatusSchedule.DAILY || scheduleType === StatusSchedule.VISIT
                    || scheduleType === StatusSchedule.CLASS || scheduleType === StatusSchedule.FOLLOWUP
                    || scheduleType === StatusSchedule.CONTACT || scheduleType === StatusSchedule.HIRED
                    || scheduleType === StatusSchedule.VISITED || scheduleType === StatusSchedule.RESCHEDULED ?
                    <div style={{ position: 'absolute', height: '7rem', width: width, borderRadius: 4 }} />
                    :
                    null
            }
            <div style={{ position: 'absolute', top: 0, left: '18%', overflow: 'hidden', height: '7rem', width: '90%', border: 'none', borderRadius: 4 }}>
                <div className="grid" style={{ position: 'relative', width: '90%', marginTop: 5, marginLeft: 5, marginRight: 5 }}>
                    <div className="col-8" style={{ fontSize: '0.9rem', padding: '0.1rem' }}>
                        <b>{limitCharacters(schedule.guestName || schedule.guest?.pushName, 16)}</b>
                    </div>
                    <div className="col-4" style={{ fontSize: '0.8rem', padding: '0.1rem', paddingRight: '0.8rem', textAlign: 'right' }}>
                        <b>{moment(schedule.date).format('DD/MM')}</b>
                    </div>
                    <div className="col-6" style={{ padding: '0.1rem' }}>
                        <i>Horário:</i>
                    </div>
                    <div className="col-6" style={{ padding: '0.1rem' }}>
                        <b>{moment(schedule.date).format('HH:mm')}h</b>
                    </div>
                    <div className="col-6" style={{ padding: '0.1rem' }}>
                        <i>Atendente:</i>
                    </div>
                    <div className="col-6" style={{ padding: '0.1rem' }}>
                        <b>{limitCharacters(schedule.invitedBy?.nickname, 15)}</b>
                    </div>
                    <div className="col-6" style={{ padding: '0.1rem' }}>
                        <i>Unidade:</i>
                    </div>
                    <div className="col-6" style={{ padding: '0.1rem' }}>
                        <b>{limitCharacters(replaceValue(schedule.branchGym?.name, 'UNIDADE '), 15)}</b>
                    </div>
                    <div className="col-6" style={{ padding: '0.1rem' }}>
                        <i>Situação:</i>
                    </div>
                    <div className="col-6" style={{ padding: '0.1rem' }}>
                        <b>{schedule.statusSchedule === 'opened' ? 'Aberto' : 'Encerrado'}</b>
                    </div>
                </div>
            </div>
            <div style={{ position: 'relative', top: -1, left: -1, backgroundColor: '#A58A2E', height: '7rem', width: '15%', border: '1px solid #707070', borderRadius: 4 }}>
                <div style={{ position: 'absolute', fontSize: '0.8rem', minWidth: '7rem', textAlign: 'center', fontWeight: 'bold', margin: 0, top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%) rotate(270deg)' }}>
                    {schedule.typeSchedule === 'daily' ? 'DIÁRIA' :
                        schedule.typeSchedule === 'visit' ? 'VISITA PROGRAMADA' :
                            schedule.typeSchedule === 'class' ? 'AULA EXPERIMENTAL' :
                                // schedule.typeSchedule === 'contact' ? 'CONTATO PESSOAL' :
                                schedule.typeSchedule === 'followup' ? 'FOLLOW-UP' :
                                    ''}
                </div>
            </div>
            {/* {
                customerId !== schedule.invitedBy?._id ?
                    <div style={{ position: 'absolute', top: '75%', left: '86.5%', width: '12%', height: '22%', backgroundColor: '#0008', verticalAlign: 'middle', textAlign: 'center', borderRadius: 50 }}>
                        <i style={{ fontWeight: 'bolder', fontSize: '0.9rem', marginTop: '18%' }} className='pi pi-lock' />
                    </div>
                    : null
            } */}
        </div>
    )
}